.amgdprcookie-groups-modal {
    .modal-inner-wrap {
        width: 75%;

        .modal-header {
            .action-close {
                padding: 0.625rem;
            }
        }

        .modal-content {
            padding: 0 3.6rem;
        }

        .amgdprcookie-cookie-container {
            .amgdprcookie-toggle-cookie {
                .amgdprcookie-input + .amgdprcookie-label {
                    margin-right: 2rem;
                }
            }
        }
    }
}

.amgdprjs-bar-template {
    .amgdprcookie-bar-container {
        padding: 0.625rem 1.25rem 0.625rem 1.563rem;
        .amgdprcookie-buttons-block {
            .amgdprcookie-button {
                letter-spacing: 1.35px;
                margin: 0.625rem;
            }

        }
    }

}
