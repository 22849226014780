.cart-container {
    .widget {
        float: left;
        &.block {
            margin-bottom: 20px;
        }
    }

    .form-cart {
        width: 68%;
    }
}
.column {
    &.main {
        .cart-summary {
            .actions-toolbar {
                & > .secondary {
                    float: none;
                }
            }
        }
    }
}
.cart-summary {
    float: right;
    position: relative;
    width: 28%;

    .block {
        .fieldset {
            .field {
                margin: 0 0 20px;
                margin: 0 0 10px;
                &:not(.choice) {
                    & > .label {
                        box-sizing: content-box;
                        float: none;
                        width: auto;
                        text-align: left;
                        padding: 0;
                    }
                    & > .control {
                        float: none;
                        width: auto;
                    }
                }
                & > .label {
                    margin: 0 0 5px;
                    display: inline-block;
                }
            }
        }
    }
}
.cart-summary .block .fieldset .field.choice:before,
.cart-summary .block .fieldset .field.no-label:before {
    display: none;
}
.cart {
    &.table-wrapper {
        .items {
            min-width: 100%;
            width: auto;
        }
        .item {
            .col {
                &.item {
                    padding: 20px 8px 10px;
                }
            }
        }
        .item-actions {
            td {
                text-align: right;
            }
        }
        .product-item-photo {
            display: table-cell;
            max-width: 100%;
            padding-right: 20px;
            position: static;
            vertical-align: top;
            width: 1%;
        }
        .product-item-details {
            display: table-cell;
            vertical-align: top;
            white-space: normal;
            width: 99%;
        }
        .cart-products-toolbar {
            & + .cart {
                thead {
                    tr {
                        th {
                            &.col {
                                padding-bottom: 7px;
                                padding-top: 8px;
                            }
                        }
                    }
                }
            }
        }
        .cart {
            & + .cart-products-toolbar {
                margin-top: 25px;
            }
        }
    }
    .action {
        margin-bottom: 10px;
    }
}
.cart-products-toolbar {
    .toolbar-amount {
        line-height: 30px;
        margin: 0;
    }
    .pages {
        float: right;
        margin: 0 0 1px;
        .item {
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
