.account {
    .columns {
        flex-direction: column;
    }

    .account-nav-title {
        display: block;

        &::after {
            display: none;
        }
    }

    .account-nav-content {
        max-height: 50rem;
        border-bottom: 1px solid $lighter-grey;
    }

    .account-nav-title {
        border: none;
    }

    .sidebar-main {
        width: 100% !important;
        margin-bottom: 2.5rem;
        padding-right: 0;
    }

    .column.main {
        width: 100% !important;

        .block:not(.widget) {
            &.block-dashboard-info,
            &.block-dashboard-addresses,
            &.block-addresses-default {
                .block-content {
                    flex-direction: row;
                }

                .box {
                    width: 50%;
                }
            }
        }
    }

    .box .box-content {
        font-size: 0.9rem;

        address {
            line-height: 1.4rem;
        }

        p {
            font-size: 0.9rem;
        }
    }

    .orders-history .table-order-items thead .col {
        letter-spacing: 0.5px;
        font-size: 0.9rem;
    }

    .order-products-toolbar .pager {
        font-size: 0.8rem;

        select {
            font-size: 0.8rem;
        }
    }

    .table-downloadable-products,
    .table-additional-addresses-items,
    .table-order-items {
        .col.actions {
            &.order {
                display: table-cell !important; //override overly specific selector from _blank
            }

            &.view svg {
                display: inline !important; //override overly specific selector from _blank
            }

            .view .action-label {
                display: none;
            }
        }

        .col {
            width: 0;
            font-size: 0.9rem;

            &.id,
            &.date,
            &.actions {
                width: 0;
                word-wrap: break-word;
                overflow-wrap: break-word;
                white-space: nowrap;
            }

            .cart-price {
                font-size: 0.9rem;
            }
        }

        tr {
            padding: 0;
        }

        td {
            padding: 0.75rem !important; //override overly specific selector from _blank
        }

        tbody {
            .col.id {
                padding-top: 0.75rem !important; //override overly specific selector from _blank
            }

            .col.actions.view {
                padding-bottom: 0.75rem !important; //override overly specific selector from _blank
            }
        }
    }

    form .fieldset .legend {
        margin-left: 0;
    }
}

// Hover
.account-nav .item a:hover {
    background-color: $lightest-grey;
}

.box .box-content address a:hover,
.account .block .block-title a:hover,
.account .actions-toolbar .secondary a:hover,
.account .table-order-items .col.actions a:hover,
.account .box .box-actions a:hover {
    text-decoration: underline;
}

.account-nav .item a,
.account-nav .item.current a,
.account-nav .item.current strong,
.account .actions-toolbar .secondary a,
.account form,
.account .loyaltycard p,
.account .message.info,
.account .block .box .box-actions a,
.account .block .block-title a {
    font-size: 0.9rem;
}

.account .block-title strong,
.account form .fieldset .legend {
    font-size: 1.4rem;
}

.customer_account_dashboard_title {
    margin-bottom: 1rem;
    font-size: 1.6rem;
}

.box .box-title {
    font-weight: 500;
    font-size: 1.2rem;
}

.ticket-listing {
    --nb-colmuns: 4;
}

.subscription-product-blocks {
    display: flex;
    flex-wrap: wrap;
    margin: 0.5em 0 3rem;

    .gallery-placeholder {
        width: 50%;
        padding: 0 2em 0 2em;
    }

    .subscription-product-infos {
        width: 48%;
        padding-left: 2em;

        .product-price {
            display: flex;
            border-bottom: 1px solid $lighter-grey;
            margin-bottom: 1em;
            padding-bottom: 0.5em;

            .price,
            .stock span {
                font-size: 1rem;
                padding-right: 0.5em;
            }

            .product-info-attributes-container {
                margin-left: auto;
            }
        }
    }
}
