.opc-wrapper,
.opc-progress-bar{
    width: calc(100% - 480px); //480px = $opc-sidebar__width in checkout.scss for xxs breakpoint
}

.opc-wrapper {
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    padding-right: 30px;
}
.opc-progress-bar-item {
    width: 185px;
}
.opc-wrapper .form-login,
.opc-wrapper .form-shipping-address {
    max-width: 600px;
}
.opc-summary-wrapper .modal-header .action-close {
    display: none;
}

.opc-block-summary {
    .minicart-items-wrapper {
        max-height: 483px;
    }
}

.opc-payment {
    width: 100%;
}

.checkout-onepage-success {
    .print {
        float: right;
        margin-top: 2.25rem;
    }

    .checkout-success {
        margin-top: 2rem;
    }
}
