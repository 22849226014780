.customer-account-create, .customer-account-edit {
    ul.select2-results__options {
        .select2-results__option.select2-results__option--selectable.select2-results__option--highlighted {
            background: $dark-green;
            color: white;
        }
    }

    #maincontent {
        div.column.main {

            div.account.create.form.container {
                border: 1px solid $lighter-grey;
                margin-top: 4rem;
                padding-top: 6rem;
            }

            h1.account.create.title {
                margin-top: 2rem;
            }

            form.create.account.form-create-account,
            form.form-edit-account {
                width: 75%;

                div.account-create-input-container {
                    div.account-create-input-field > label {
                        font-weight: normal;
                   }
               }

               span.select2-selection__rendered {
                   font-weight: normal;
               }
            }
        }
    }
}
