$padding-horizontal: $padding-horizontal-sm;
$card-width: 380px;
$card-height: 285px;
$actu-gap: 1.25rem;

body.contentmanager-contenttype-homepage {
    .page-main {
        .homepage {
            section {
                + .homepage_actualites {
                    .homepage_actualites-cards {
                        .actu-card {
                            margin: 0;
                            margin-bottom: $actu-gap;

                            .actu-card-sticker {
                                position: absolute;
                                text-transform: capitalize;
                                font-size: 75%;
                                padding: 8px 15px;
                                right: 0;
                                top: 0;
                                color: $black;
                                background: #ffffffdd;
                            }
                            .actu-card-image {
                                width: 100%;
                                overflow: hidden;

                                img {
                                    display: inherit;
                                    position: inherit;
                                    top: inherit;
                                    left: inherit;
                                    transform: unset;
                                }

                            }
                        }
                        .actu-card-text {
                            display: block;
                            text-align: justify;
                            font-size: 0.9rem;
                            height: 5.3rem;
                            overflow: hidden;
                        }

                        .actu-card-textual-infos {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            padding: 18px;
                            height: calc(100% - ((100vw - 90px) / 3) / 4 * 3);

                            .actu-card-title {
                                .actu-card-title-link {
                                    font-size: 1rem;
                                    height: 2.2rem;
                                }
                            }
                        }

                        .actu-card-link {
                            position: relative;
                            display: inline-block;
                            height: auto;
                            font-size: 1rem;
                            line-height: 2rem;
                        }

                        .actu-card-tags {
                            .product-tags__item {
                                margin: 0;

                                .tag {
                                    font-size: 0.9rem;
                                    margin-right: 1ch;

                                    &::before {
                                        content: '#';
                                    }
                                }
                            }
                        }

                        .actu0 {
                            padding: 46px calc(100% - 600px) 24px 80px;
                            aspect-ratio: auto;

                            .actu-card-text {
                                margin: 20px 0;
                                height: 5rem;
                            }

                            .actu-card-tags {
                                margin: auto 0;

                                .product-tags {
                                    .product-tags__item {
                                        .tag {
                                            text-decoration: none;
                                            background: unset;
                                        }
                                    }
                                }
                            }
                        }

                        .actu1,
                        .actu2,
                        .actu3 {
                            height: unset;
                            min-height: 500px;
                            width: calc((100% - 2 * #{$actu-gap}) / 3);
                            display: block;
                            color: $black;

                            .actu-card-textual-infos {
                                position: relative;
                                background: none;
                            }

                            .actu-card-link {
                                color: $black;
                                background-image: linear-gradient($black, $black);
                            }

                            &:not(:nth-child(3n)) {
                                margin-right: 1.25rem;
                            }
                        }
                        .homepage_actualites-subcards {
                            flex-direction: row;
                        }
                    }
                }

                &.homepage_recherche {
                    height: 350px;
                }

                + .homepage_services {
                    .service-block {
                        .service-cta {
                            button {
                                width: auto;
                            }
                        }
                    }
                }

                .especes-grid {
                    padding: 0 $padding-horizontal;
                }

                + .homepage_thematiques {
                    .ct-field-repeater_fields {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        grid-template-rows: 1fr 1fr 1fr 1fr;
                        gap: 0;
                        grid-template-areas:
                    "one one one two two"
                    "three three four four four"
                    "five five five six six"
                    "seven seven seven seven seven"
                    "eight eight eight nine nine"
                    "ten ten eleven eleven eleven";

                        .rubrique {
                            &::before,
                            &::after {
                                content: unset;
                            }
                            &:nth-child(1),
                            &:nth-child(3),
                            &:nth-child(5),
                            &:nth-child(9) {
                                padding-left: $padding-horizontal;
                                padding-right: 2rem;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: calc(((#{$container-sm} - 2rem) - 100vw) / 2);
                                    width: calc((100vw - (#{$container-sm} - 2rem)) / 2);
                                    background: inherit;
                                }
                                &::after {
                                    content: unset;
                                }
                            }

                            &:nth-child(2),
                            &:nth-child(4),
                            &:nth-child(6),
                            &:nth-child(8),
                            &:nth-child(10) {
                                &::before {
                                    content: unset;
                                }
                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    right: -1rem;
                                    width: 1rem;
                                    height: 100%;
                                    background: inherit;
                                }
                                padding-left: 2rem;
                                padding-right:  $padding-horizontal;
                            }

                            &:nth-child(7) {
                                padding-left: $padding-horizontal;
                                padding-right:  $padding-horizontal;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: calc(((#{$container-sm} - 2rem) - 100vw) / 2);
                                    width: calc((100vw - (#{$container-sm} - 2rem)) / 2);
                                    background: inherit;
                                }
                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    right: -1rem;
                                    width: 1rem;
                                    height: 100%;
                                    background: inherit;
                                }
                            }

                            &:nth-child(11) {
                                padding-left:  $padding-horizontal;
                                padding-right:  $padding-horizontal;
                                &::before,
                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    width: 1rem;
                                    height: 100%;
                                    background: inherit;
                                }
                                &::before {
                                    left: -1rem;
                                }
                                &::after {
                                    right: -1rem;
                                }
                            }
                        }

                    }
                }

                + .homepage_ctifl {
                    .homepage_ctifl_upper {
                        .ctifl_upper_block {
                            .ct-field {
                                font-size: 14px;
                                text-align: justify;
                            }
                            .ctifl_image_container {
                                max-width: 550px;
                            }
                        }
                    }

                    .homepage_ctifl_bottom {
                        .floating_logo {
                            img   {
                                bottom: calc(193px + 5vw);
                            }
                        }
                        &_figures {
                            font-size: 3.125rem;
                            text-align: left;
                        }

                        p {
                            font-size: 0.8rem;
                        }
                    }
                }
            }
            .homepage_social_body {
                .homepage_social_first_col {
                    flex-grow: 1;

                    .homepage-youtube {
                        iframe {
                            height: 420px;
                        }
                    }
                }
            }
        }
    }
}
