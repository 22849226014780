$header-menu-height: 4rem;

$header-icon-spacing: 1.75rem;

.page-header {
    display: flex;
    flex-direction: column;
    padding: 0;

    .header-banner-wrapper {
        margin: 0;
    }

    .header-banner {
        padding: 0;
    }

    .panel {
        &.wrapper {
            float: none;
            width: auto;
            padding-right: 0;

            .header-socials {
                display: block;
            }
        }

        &.header {
            .minicart-wrapper {
                .action.showcart {
                    padding: 0 0.5rem 0 0;
                }
            }
        }
    }

    .header.content {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        padding: 0 1rem 0;
        width: 100%;

        .header-socials {
            display: none;
        }

        &::before, &::after {
            content: unset;
            display: unset;
        }
    }

    .logo {
        float: none;
        margin-bottom: 0;
        padding: 0.5rem 0;
        max-height: $header-menu-height;

        img {
            width: auto;
            height: auto;
            padding: 0;
            max-height: 100%;
        }
    }

    .minicart-wrapper {
        position: relative;
        margin-left: $header-icon-spacing;
        padding-left: $header-icon-spacing;

        @include header-separator;

        &::after {
            left: 0;
            border-color: $lighter-grey;
        }
    }

    .algolia-search-block {
        position: relative;
        top: 0;
        left: auto;
        width: auto;
        margin-right: $header-icon-spacing;
        padding-right: $header-icon-spacing;

        @include header-separator;

        .algolia-search-input {
            margin: 0;
            padding-left: 0.5rem;
            width: 15rem;
            height: 2rem; //$header-link-element-height in xxs/header.scss
            border-color: transparent;
            border-bottom: 1px solid $primary;
            box-shadow: none;
        }

        &::after {
            right: 0;
            border-color: $lighter-grey;
        }
    }

    .nav-sections {
        height: auto;
    }

    .navigation {
        float: none;
        margin-left: auto;
        flex-basis: content;

        .menu-main-ul {
            &::after {
                content: '';
                display: block;
                clear: both;
            }
        }

        li.level0 {
            float: left;
            margin-bottom: 0;

            &.mobile-nav-icon {
                > a.level-top span, > span {
                    &::before {
                        display: none;
                    }
                }
            }

            > a.level-top span, > span {
                position: relative;
                display: inline-block;
                padding: 0 0.5rem;
                line-height: $header-menu-height;

                @include header-separator;

                &::after {
                    right: 0;
                }
            }

            &:last-child {
                > a.level-top span, > span {
                    &::after  {
                        display: none;
                    }
                }
            }

            ul.level0.submenu {
                position: absolute !important;
                background-color: $white;

                @include light-box-shadow-and-top-inset(0.25);
            }

            &.big {
                > ul.submenu {
                    left: 0 !important;
                    margin: 0;
                    padding: 2rem 0;
                    min-width: 100%;
                    background-color: $white;
                    list-style: none;

                    ul {
                        position: initial !important;
                        max-width: 100%;
                    }

                    li {
                        max-width: 100%;
                    }
                }

                li.level1.container {
                    margin: 0 auto;
                    padding: 0 20px;
                    max-width: $container-hd;

                    ul {
                        display: initial !important;
                    }
                }

                li.level2.col {
                    float: left;
                    padding-right: 15px;
                    padding-left: 15px;
                    width: 25%;

                    > ul {
                        max-width: 100%;
                    }

                    &[class*="col--"] {
                        li.level3 {
                            position: relative;

                            > span {
                                padding: 0.25rem 1rem 0.25rem 0.5rem;
                                background-color: $white;

                                &::after {
                                    content: '\00a0';
                                    position: absolute;
                                    top: calc(1em / 2);
                                    z-index: -1;
                                    display: block;
                                    width: 100%;
                                    height: 1em;
                                    background-image: linear-gradient($primary, $primary);
                                    background-position: 0 50%;
                                    background-repeat: no-repeat;
                                    background-size: 100% 3px;
                                }
                            }
                        }

                        ul.level3 {
                            display: flex !important;

                            .sublist {
                                width: 100%;
                            }
                        }
                    }

                    &.col--2 {
                        width: 50%;
                    }

                    &.col--3 {
                        width: 75%;
                    }

                    &.col--4 {
                        width: 100%;
                    }
                }

                li.level3 {
                    $header-navigation-underline-size: 1px;
                    $header-navigation-hover-color: $primary;

                    > span, a {
                        padding: 0.25rem 0.5rem;
                        width: auto;
                    }

                    a {
                        > span {
                            @include link-underline($black, 1ch, 1.2rem, $header-navigation-underline-size);
                        }

                        &:hover, a:focus {
                            > span {
                                color: $header-navigation-hover-color;
                                background-image: linear-gradient($header-navigation-hover-color, $header-navigation-hover-color);
                                background-size: 100% $header-navigation-underline-size;
                            }
                        }
                    }

                    //display Magento blocks (img links) on mobile
                    &.menu-img-link {
                        display: block;

                        //Hide the replacement mobile link
                        + li {
                            display: none;
                        }
                    }
                }

                li.level4 {
                    padding-left: 1.5rem;

                    a {
                        width: auto;
                    }
                }

                .pagebuilder-column-group {
                    display: flex !important; //Display cms block content above mobile
                }
            }

            &:not(.big) {
                a.level1 {
                    padding: 0.5rem;
                }
            }
        }
    }

    .nav-sections-items {
        margin-top: 0;
    }
}

.header-socials {
    display: block;

    .header-socials__contact {

    }
}

//TODDO temporary, remove on searchbar integration
.algolia-search-block {
    display: block;
}
