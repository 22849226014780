.mgt-xxs { display: none; }
.mgt-xs  { display: none; }
.mgt-sm  { display: block; }
.mgt-md  { display: none; }
.mgt-lg  { display: none; }
.mgt-hd  { display: none; }

#mgt-developer-toolbar {
    position: fixed;
    background: #2ea9ec;
    color:black;
}
//.mgt-developer-toolbar-blocks {
//    padding: 0 20px 0 0;
//    .mgt-developer-toolbar-block {
//        padding: 5px 10px;
//        margin:  5px 10px;
//        &-magento-version,
//        &-php-parsetime,
//        &-memory-consumption,
//        &-database-queries {
//            svg {
//                width: 20px;
//                height: 20px;
//            }
//        }
//        a span {
//            display: block;
//        }
//    }
//}
