$related-contents__items-gap : 1.25rem;

.related-contents {
    .card-list {
        flex-wrap: wrap;
    }

    .card-list__item {
        width: calc((100% - 3 * #{$related-contents__items-gap})/4);

        &:not(:nth-child(4n)) {
            margin-right: $related-contents__items-gap;
        }
    }
}
