:root {
    --container-width: #{$container-sm};
}

html, body {
    font-size: $font-size-sm;
}

.page-header .header.panel,
.page-header .welcome-wrapper,
.header.content,
.breadcrumbs,
.page-main,
.page-wrapper > .widget,
.page-wrapper > .page-bottom,
.block.category.event,
.top-container,
.newsletter-inner,
.block-content-footer,
.footer-copyright {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--container-width);
    padding-left: 1rem;
    padding-right: 1rem;
}
.navigation {
    box-sizing: border-box;
    width: auto;
    max-width: $container-hd;
}

// Container size
//@include container-sizing($container-sm);
