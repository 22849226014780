$card-image-height: 166px;

body.search-content-index,
body.algolia-list,
body.algolia-list-actu {
    #algolia_instant_selector {
        max-width: 730px;
        margin-inline: auto;
    }
}

body.search-content-index,
body.algolia-list {
    .row {
        .algolia-right-container {
            .ais-Hits-list {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 20px 20px;
                grid-auto-flow: row;
                grid-template-areas:
                "actu-main actu-main actu-sub1"
                "actu-main actu-main actu-sub2"
                "actu-result1 actu-result1 actu-result1"
                "actu-result2 actu-result2 actu-result2"
                "actu-result3 actu-result3 actu-result3";
            }
            .ais-Hits-item {
                width: 221.33px;
                margin-bottom: 0;
                .instant-hit-template {
                    width: 221.33px;
                    .result-wrapper {
                        .result {
                            height: 415px;
                            .result-sub-content-img-container {
                                height: $card-image-height;
                                position: relative;
                                top: 0;
                                left: 0;
                                right: 0;
                                width: 100%;
                                overflow: hidden;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .result-sub-content-img {
                                    height: $card-image-height;
                                    img {
                                        height: 100%;
                                    }
                                }
                            }
                            .result-content {
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    .ais-Hits-item {
        .result-wrapper {
            .result {
                .result-sub-content-img-container {
                    .result-sub-content-img {
                        img {
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}

body.algolia-list-actu {
    .algolia-right-container {
        position: relative;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        margin-bottom: 2rem;

        .ais-Hits-item {
            margin: 1rem auto;
        }
    }
}

.block-search {
    float: right;
    padding-left: 15px;
    position: relative;
    width: 250px;
    z-index: 4;
    .control {
        border-top: 0;
        margin: 0;
        padding: 0 0 25px;
    }
    .nested {
        display: block;
        padding-top: 5px;
        position: absolute;
    }
    input {
        margin: 0;
        padding-right: 35px;
        position: static;
        &::-webkit-input-placeholder {
            color: #c2c2c2;
        }
        &:-moz-placeholder {
            color: #c2c2c2;
        }
        &::-moz-placeholder {
            color: #c2c2c2;
        }
        &:-ms-input-placeholder {
            color: #c2c2c2;
        }
    }
    .action {
        &.search {
            display: inline-block;
            background-image: none;
            background: none;
            -moz-box-sizing: content-box;
            border: 0;
            box-shadow: none;
            line-height: inherit;
            margin: 0;
            padding: 0;
            text-decoration: none;
            text-shadow: none;
            font-weight: 400;
            padding: 5px 0;
            position: absolute;
            right: 10px;
            top: 0;
            z-index: 1;
            & > span {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
            &::before {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 22px;
                line-height: 22px;
                color: #8f8f8f;
                content: '\e615';
                font-family: 'icons-blank-theme';
                margin: 0;
                vertical-align: top;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
            }
            &:hover {
                background: none;
                border: none;
                &::before {
                    color: inherit;
                }
            }
            &:active {
                &::before {
                    color: inherit;
                }
            }
            &:focus {
                &::before {
                    color: $black;
                }
            }
        }
    }
}
.block-search .action.search:focus,
.block-search .action.search:active {
    background: none;
    border: none;
}
.block-search .action.search.disabled,
.block-search .action.search[disabled],
fieldset[disabled] .block-search .action.search {
    pointer-events: none;
    opacity: 0.5;
}
.search-autocomplete {
    margin-top: 0;
}
