.press-space {
    &-navigator {
        display: block;
        position: sticky;
        top: $top-sticky-offset;
        &-title {
            display: flex;
            justify-content: space-between;
        }
    }
    &-body {
        width: calc(100% - 320px);
        margin-left: 320px;
    }
}
