$new-account-button: 208px;

.popup-authentication {
    .modal-inner-wrap {
        .modal-header {
            background-color: $white;
            z-index: 3;
        }
    }
}

.block-authentication {
    .block-title {
        font-size: 1.4rem !important;
        border-bottom: 0;
        margin-bottom: 40px;
        margin-top: 40px;

    }

    .field .label {
        font-weight: 400;
    }
    .actions-toolbar {
        > .primary {
            display: inline;
            float: right;
            margin-right: 0;
            .action {
                margin-right: 0;
            }
        }
        > .secondary {
            float: right;
        }
    }

}




.popup-authentication {
    .modal-inner-wrap {
        min-width: 768px;
        width: 60%;
    }
    .block-authentication {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        border-top: 1px solid #c1c1c1;
    }
    .block[class],
    .form-login,
    .fieldset,
    .block-content {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-grow: 1;
        flex-grow: 1;
        .label {
            margin-bottom: 1rem;
        }
        .control {
            margin-top: 1rem;
        }
    }

    .block-customer-login,
    .block[class] {
        box-sizing: border-box;
        float: left;
        padding: 10px 30px 0 0;
        max-width: 50%;
        font-size: 1.1rem;
        margin: 0;
        ul {
            list-style: none;
            padding-left: 10px;
        }
        .field .control,
        .field .label {
            float: none;
            width: auto;
        }
        + .block {
            border-left: 1px solid $lighter-grey;
            border-top: 0;
            padding: 10px 0 0 40px;
            margin: 0;
            &::before {
                content: attr(data-label);
                left: 0;
                top: 50%;
            }
        }
    }

    .block-customer-login,
    .block-new-customer {
        position: relative;
        .block-content {
            position: relative;
            .actions-toolbar {
                margin-bottom: 0;
                position: absolute;
                bottom: 0;
                right: 0;
                button {
                    margin-right: 0;
                }
            }
        }
    }
    .fieldset.login {
        margin-bottom: 0;
        .field.password {
            margin-bottom: 90px;
        }
        .actions-toolbar {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;
            max-width: unset;
            .primary {
                width: unset;
            }
            .secondary {
                float: none;
                font-size: 1rem;
                a {
                    font-size: 1rem;
                    color: $primary;
                    &:active,
                    &:hover {
                        color: $black;
                    }
                }
            }
        }
    }
}
.login-container {
    height: 400px;

    .block {
        .block-title {
            font-size: 1.3rem;
            margin-bottom: 15px;
            padding-bottom: 12px;
        }
    }

    .block-customer-login {
        margin-top: 1rem!important;
        .fieldset.login {
            .actions-toolbar {
                flex-direction: row-reverse;
                justify-content: space-between;
                &::before,
                &::after {
                    content: unset;
                }
                .primary,
                .secondary{
                    margin: 0 !important;
                }
                .primary {
                    width: auto;
                .primary {
                    //max-width: 180px;
                    width: auto;
                    padding: 0.765rem 1.5rem;
                    margin-top: 0;
                    border-radius: 2px;
                    line-height: 1rem;
                    text-align: center;
                    transition: all 0.3s ease;
                    display: inherit;
                }
                }
            }
        }
    }
    .block-new-customer {
        .block-content {
        position: relative;
            height: 100%;
            .actions-toolbar {
                position: absolute;
                top: $new-account-button;
                right: 0;
            }
        }
    }
}
