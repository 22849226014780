$product-data__switch-item-p-vertical: 0.5rem;
$product-data__switch-item-fs: 1.125rem;

$PRODUCT-DATA__SWITCH-ITEM-HEIGHT: calc(#{$product-data__switch-item-fs} + 2*#{$product-data__switch-item-p-vertical});

.product {
    &.data {
        &.items {
            position: relative;
            z-index: 1;

            &::after {
                clear: both;
            }

            & > .item {
                &.title {
                    float: left;
                    position: relative;
                    z-index: 2;
                    margin-right: 1.25rem;
                    width: calc(25% - 1.25rem);
                    border-bottom: 1px solid transparent;

                    & > .switch {
                        display: block;
                        position: relative;
                        z-index: 2;
                        padding: $product-data__switch-item-p-vertical 0;
                        color: $light-grey;
                        text-decoration: none;
                        line-height: $product-data__switch-item-fs;
                        font-size: $product-data__switch-item-fs;
                        font-weight: 600;

                        &:visited, &:hover, &:active {
                            color: $light-grey;
                            text-decoration: none;
                        }
                    }

                    &.active {
                        border-bottom-color: $primary;

                        .switch {
                            color: $primary;
                        }
                    }
                }

                &.content {
                    margin-top: $PRODUCT-DATA__SWITCH-ITEM-HEIGHT;
                    box-sizing: border-box;
                    float: right;
                    margin-left: -100%;
                    width: 100%;
                    border-top: 1px solid #d1d1d1;

                    &::after {
                        clear: both;
                    }

                    &.active {
                        display: block;
                    }
                }
            }
        }
    }
}

.product.data.items:before,
.product.data.items:after {
    content: '';
    display: table;
}

.product.data.items > .item.content:before,
.product.data.items > .item.content:after {
    content: '';
    display: table;
}

.product-info-main  {
    .product-info-attributes-container {
        display: flex;
        align-items: baseline;
        margin-bottom: 1.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid $lighter-grey;

        > * {
            width: 100%;
        }
    }

    .product-info-price {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }

    .existing_subscription_text {
        display: none;
        font-style: italic;
        color: $primary;
        margin-top: 1rem;
    }

    .disabled-waiting-for-subscription {
        opacity: 50%;
        pointer-events: none;
    }

    .product-ctifl-references {
        margin-bottom: 0;
        text-align: right;
        display: flex;
        align-items: end;

        p {
            display: inline-block;
            width: 100%;
        }
    }
}

.page-product-downloadable {
    .product-info-main {
        .product-info-price {
            display: block;
            margin-bottom: 1.5rem;
            padding-bottom: 0.5rem;
            border-bottom: 1px solid $lighter-grey;
        }

        .items.samples {
            margin-bottom: 1rem;
        }

        .samples-item-title {
            display: none;
        }

        .field.downloads {
            > .label {
                display: none;
            }
        }
    }
}

.gallery-placeholder__image {
    max-height: 466px; //Current max calculated height for a square ratio fotorama-gallery item for this breakpoint and above
}
