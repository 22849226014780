.aw-et {
    &-view-options-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        transition: 1s;
        justify-content: space-between;

        .options-container {
            width: 57%;
        }

        .summary-container {
            width: 40%;
            position: -webkit-sticky;
            position: sticky;
            top: 9rem;
            margin-left: 3%;
        }
    }
}
