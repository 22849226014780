.block.newsletter {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .newsletter-inner {
        margin: 0 auto;
        width: 100%;
        flex-direction: row;
        .subscribe-text {
            width: 48%;
        }

        .content {
            width: 50%;

            .form.subscribe {
                display: table;
                width: 100%;
                max-width: 400px;
                margin-left: auto;
            }

            .fieldset {
                display: table-cell;
                margin: 0;
                padding: 0;
                vertical-align: top;
            }
        }
    }
}

.footer {
    .ct-view-footer {
        .block-content-footer {
            padding: 60px 0;

            .columns-footer {
                flex-direction: row;
                text-align: unset;
            }

            .social-media {
                .ct-field-repeater_fields {
                    margin: 0 !important;

                    .ct-view-footer_link {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
