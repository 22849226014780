/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.login-container .g-recaptcha,
.form-login .g-recaptcha,
.form-edit-account .g-recaptcha {
    margin-bottom: 10px !important;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.required-captcha.checkbox {
    position: absolute;
    display: block;
    visibility: visible;
    overflow: hidden;
    opacity: 0;
    width: 1px;
    height: 1px;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.block.newsletter .field-recaptcha .field .control:before {
    content: none;
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
.form.send.friend .g-recaptcha {
    margin-top: 40px;
}
@media only screen and (max-width: 768px) {
    .field-tooltip .field-tooltip-content {
        left: auto;
        right: -10px;
        top: 40px;
    }
    .field-tooltip .field-tooltip-content::before,
    .field-tooltip .field-tooltip-content::after {
        border: 10px solid transparent;
        height: 0;
        left: auto;
        margin-top: -21px;
        right: 10px;
        top: 0;
        width: 0;
    }
    .field-tooltip .field-tooltip-content::before {
        border-bottom-color: #999999;
    }
    .field-tooltip .field-tooltip-content::after {
        border-bottom-color: #f4f4f4;
        top: 1px;
    }
}
/**
 * Copyright Â© Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
*/
@media only screen and (min-device-width: 320px) and (max-device-width: 780px) and (orientation: landscape) {
    .product-video {
        height: 100%;
        width: 81%;
    }
}
@media all and (min-width: 769px), print {
    .abs-product-options-list-desktop dt,
    .block-giftregistry-shared .item-options dt {
        clear: left;
        float: left;
        margin: 0 10px 5px 0;
    }
    .block-giftregistry-shared .item-options dt:after {
        content: ': ';
    }
    .abs-product-options-list-desktop dd,
    .block-giftregistry-shared .item-options dd {
        display: inline-block;
        float: left;
        margin: 0 0 5px;
    }
    .abs-button-desktop {
        width: auto;
    }
    .abs-blocks-2columns,
    .column .block-addbysku .block-content .box,
    .login-container .block,
    .account .column.main .block:not(.widget) .block-content .box,
    .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box,
    [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box,
    .sales-guest-view .column.main .block:not(.widget) .block-content .box {
        width: 48.8%;
    }
    .column .block-addbysku .block-content .box:nth-child(odd),
    .login-container .block:nth-child(odd),
    .account .column.main .block:not(.widget) .block-content .box:nth-child(odd),
    .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box:nth-child(odd),
    [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box:nth-child(odd),
    .sales-guest-view .column.main .block:not(.widget) .block-content .box:nth-child(odd) {
        clear: left;
        float: left;
    }
    .column .block-addbysku .block-content .box:nth-child(even),
    .login-container .block:nth-child(even),
    .account .column.main .block:not(.widget) .block-content .box:nth-child(even),
    .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box:nth-child(even),
    [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box:nth-child(even),
    .sales-guest-view .column.main .block:not(.widget) .block-content .box:nth-child(even) {
        float: right;
    }
    .abs-reset-left-margin-desktop,
    .bundle-options-container .legend.title,
    .column.main .cart-summary .actions-toolbar,
    .cart.table-wrapper .item-actions .actions-toolbar,
    .gift-summary .actions-toolbar,
    .cart.table-wrapper .gift-summary .actions-toolbar,
    .column.main .block-giftregistry-shared-items .actions-toolbar,
    .form-new-agreement .fieldset .legend,
    .form-new-agreement .actions-toolbar,
    .wishlist-index-index .main .form-wishlist-items .actions-toolbar {
        margin-left: 0;
    }
    .abs-action-remove-desktop,
    .abs-add-fields-desktop .fieldset .additional .action.remove,
    .form-giftregistry-share .fieldset .additional .action.remove,
    .form-giftregistry-edit .fieldset .additional .action.remove,
    .form-add-invitations .fieldset .additional .action.remove,
    .form-create-return .fieldset .additional .action.remove,
    .form.send.friend .fieldset .additional .action.remove {
        margin-left: 75.8%;
        top: 6px;
    }
    .form-giftregistry-share .fieldset .field:not(.choice) .control,
    .form-giftregistry-edit .fieldset .field:not(.choice) .control,
    .form-add-invitations .fieldset .field:not(.choice) .control,
    .form-create-return .fieldset .field:not(.choice) .control,
    .form.send.friend .fieldset .field:not(.choice) .control {
        width: 50%;
    }
    .abs-margin-for-forms-desktop,
    .fieldset .legend,
    .login-container .fieldset:after {
        margin-left: 25.8%;
    }
    .abs-visually-hidden-desktop,
    .modes-label,
    .block-search .label,
    .dashboard-welcome-toggler,
    .block-collapsible-nav .title {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .abs-add-clearfix-desktop:before,
    .abs-add-clearfix-desktop:after,
    .abs-pager-toolbar:before,
    .abs-pager-toolbar:after,
    .block-cart-failed .block-content:before,
    .block-cart-failed .block-content:after,
    .column .block-addbysku .block-content:before,
    .column .block-addbysku .block-content:after,
    .cart-container:before,
    .cart-container:after,
    .login-container:before,
    .login-container:after,
    .account .column.main .block:not(.widget) .block-content:before,
    .account .column.main .block:not(.widget) .block-content:after,
    .block-addresses-list .items.addresses:before,
    .block-addresses-list .items.addresses:after,
    .block-giftregistry-shared .item-options:before,
    .block-giftregistry-shared .item-options:after,
    .gift-wrapping .nested:before,
    .gift-wrapping .nested:after,
    .table .gift-wrapping .content:before,
    .table .gift-wrapping .content:after,
    .block-wishlist-management:before,
    .block-wishlist-management:after,
    .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:before,
    .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:after,
    [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:before,
    [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:after,
    .sales-guest-view .column.main .block:not(.widget) .block-content:before,
    .sales-guest-view .column.main .block:not(.widget) .block-content:after,
    .header.content:before,
    .header.content:after,
    .page-header .header.panel:before,
    .page-header .header.panel:after,
    .account .toolbar:before,
    .account .toolbar:after,
    .toolbar-giftregistry-results:before,
    .toolbar-giftregistry-results:after,
    .toolbar-wishlist-results:before,
    .toolbar-wishlist-results:after {
        content: '';
        display: table;
    }
    .abs-add-clearfix-desktop:after,
    .abs-pager-toolbar:after,
    .block-cart-failed .block-content:after,
    .column .block-addbysku .block-content:after,
    .cart-container:after,
    .login-container:after,
    .account .column.main .block:not(.widget) .block-content:after,
    .block-addresses-list .items.addresses:after,
    .block-giftregistry-shared .item-options:after,
    .gift-wrapping .nested:after,
    .table .gift-wrapping .content:after,
    .block-wishlist-management:after,
    .magento-rma-guest-returns .column.main .block:not(.widget) .block-content:after,
    [class^='sales-guest-'] .column.main .block:not(.widget) .block-content:after,
    .sales-guest-view .column.main .block:not(.widget) .block-content:after,
    .header.content:after,
    .page-header .header.panel:after,
    .account .toolbar:after,
    .toolbar-giftregistry-results:after,
    .toolbar-wishlist-results:after {
        clear: both;
    }
    .abs-add-box-sizing-desktop,
    .column.main,
    .sidebar-main,
    .sidebar-additional {
        box-sizing: border-box;
    }
    .abs-add-box-sizing-desktop-m,
    .opc-wrapper {
        box-sizing: border-box;
    }
    .abs-revert-field-type-desktop .fieldset > .field,
    .abs-revert-field-type-desktop .fieldset .fields > .field,
    .product-add-form .fieldset > .field,
    .product-add-form .fieldset .fields > .field {
        margin: 0 0 20px;
    }
    .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .label,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .label,
    .product-add-form .fieldset > .field:not(.choice) > .label,
    .product-add-form .fieldset .fields > .field:not(.choice) > .label {
        box-sizing: content-box;
        float: none;
        width: auto;
        text-align: left;
        padding: 0;
    }
    .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .control,
    .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .control,
    .product-add-form .fieldset > .field:not(.choice) > .control,
    .product-add-form .fieldset .fields > .field:not(.choice) > .control {
        float: none;
        width: auto;
    }
    .abs-revert-field-type-desktop .fieldset > .field > .label,
    .abs-revert-field-type-desktop .fieldset .fields > .field > .label,
    .product-add-form .fieldset > .field > .label,
    .product-add-form .fieldset .fields > .field > .label {
        margin: 0 0 5px;
        display: inline-block;
    }
    .abs-revert-field-type-desktop .fieldset > .field.choice:before,
    .abs-revert-field-type-desktop .fieldset .fields > .field.choice:before,
    .abs-revert-field-type-desktop .fieldset > .field.no-label:before,
    .abs-revert-field-type-desktop .fieldset .fields > .field.no-label:before,
    .product-add-form .fieldset > .field.choice:before,
    .product-add-form .fieldset .fields > .field.choice:before,
    .product-add-form .fieldset > .field.no-label:before,
    .product-add-form .fieldset .fields > .field.no-label:before {
        display: none;
    }
    .product-add-form .fieldset > .field:not(:first-child):last-of-type,
    .product-add-form .fieldset .fields > .field:not(:first-child):last-of-type {
        margin-bottom: 0;
    }
    .abs-no-display-desktop,
    .opc-estimated-wrapper,
    .sidebar .block.widget .pager .item:not(.pages-item-next):not(.pages-item-previous) {
        display: none;
    }
    .abs-pager-toolbar,
    .account .toolbar,
    .toolbar-giftregistry-results,
    .toolbar-wishlist-results {
        margin-bottom: 20px;
        position: relative;
    }
    .abs-pager-toolbar .limiter,
    .account .toolbar .limiter,
    .toolbar-giftregistry-results .limiter,
    .toolbar-wishlist-results .limiter {
        float: right;
        position: relative;
        z-index: 1;
    }
    .abs-pager-toolbar .toolbar-amount,
    .account .toolbar .toolbar-amount,
    .toolbar-giftregistry-results .toolbar-amount,
    .toolbar-wishlist-results .toolbar-amount {
        float: left;
        line-height: normal;
        padding: 7px 0 0;
        position: relative;
        z-index: 1;
    }
    .abs-pager-toolbar .pages,
    .toolbar-giftregistry-results .pages,
    .toolbar-wishlist-results .pages {
        position: absolute;
        width: 100%;
        z-index: 0;
    }
    .abs-shopping-cart-items-desktop,
    .block-cart-failed,
    .cart-container .form-cart,
    .cart-container .cart-gift-item {
        float: left;
        position: relative;
    }
    .abs-shopping-cart-items-desktop,
    .block-cart-failed,
    .cart-container .cart-gift-item {
        width: 73%;
    }
    .abs-shopping-cart-items-desktop .actions,
    .block-cart-failed .actions,
    .cart-container .form-cart .actions,
    .cart-container .cart-gift-item .actions {
        text-align: right;
    }
    .block-cart-failed .action.clear,
    .block-cart-failed .action.update,
    .cart-container .form-cart .action.clear,
    .cart-container .form-cart .action.update,
    .cart-container .cart-gift-item .action.clear,
    .cart-container .cart-gift-item .action.update {
        margin-left: 10px;
    }
    .block-cart-failed .action.continue,
    .cart-container .form-cart .action.continue,
    .cart-container .cart-gift-item .action.continue {
        float: left;
    }
    .page-main {
        width: 100%;
    }
    .column.main {
        min-height: 300px;
    }
    .page-layout-1column .column.main {
        width: 100%;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
    }
    .page-layout-3columns .column.main {
        width: 66.66666667%;
        display: inline-block;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
    }
    .page-layout-2columns-left .column.main {
        width: 83.33333333%;
        float: right;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
    }
    .page-layout-2columns-right .column.main {
        width: 83.33333333%;
        float: left;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
    }
    .sidebar-main {
        padding-right: 2%;
    }
    .page-layout-3columns .sidebar-main {
        width: 16.66666667%;
        float: left;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
    }
    .page-layout-2columns-left .sidebar-main {
        width: 16.66666667%;
        float: left;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
    }
    .page-layout-2columns-right .sidebar-main {
        width: 16.66666667%;
        float: left;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
    }
    .page-layout-2columns-right .sidebar-main {
        padding-left: 2%;
        padding-right: 0;
    }
    .sidebar-additional {
        clear: right;
        padding-left: 2%;
    }
    .page-layout-3columns .sidebar-additional {
        width: 16.66666667%;
        float: right;
        -ms-flex-order: 3;
        -webkit-order: 3;
        order: 3;
    }
    .page-layout-2columns-left .sidebar-additional {
        width: 16.66666667%;
        float: right;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
    }
    .page-layout-2columns-right .sidebar-additional {
        width: 16.66666667%;
        float: right;
        -ms-flex-order: 2;
        -webkit-order: 2;
        order: 2;
    }
    .page-layout-2columns-left .main {
        padding-left: 2%;
    }
    .page-layout-2columns-left .sidebar-additional {
        clear: left;
        float: left;
        padding-left: 0;
    }
    .panel.header {
        padding: 10px 20px;
    }
    .panel.header .links,
    .panel.header .switcher {
        display: inline-block;
    }
    .fieldset {
        > .field {
            margin: 0 0 25px;
            box-sizing: border-box;
            font-family: $roboto;
            font-weight: 900;
            > .label {
                margin: 0;
            }
            .description {
                padding: 0;
                text-align: left;
                width: 100%;
                box-sizing: border-box;
            }

        }
        &::before,
        ::after {
            content: '';
        }
        &::after {
            clear: both;
        }
        &.choice:before,
        &.no-label:before {
            padding: 0;
            width: 100%;
            box-sizing: border-box;
            content: ' ';
            height: 1px;
        }
        > .field:not(.choice) {
            > .label {
                padding: 0;
                text-align: left;
                width: 100%;
                box-sizing: border-box;
                display: inherit;
                margin: 0 0 0.3rem;
                span {
                    font-family: $roboto;
                    font-weight: 900;
                }
            }
            > .control {
                width: 100%;
            }
        }
    }
    .actions-toolbar:before,
    .actions-toolbar:after {
        content: '';
        display: table;
    }
    .actions-toolbar:after {
        clear: both;
    }
    .actions-toolbar .primary {
        float: left;
    }
    .actions-toolbar .secondary {
        float: right;
    }
    .actions-toolbar .primary,
    .actions-toolbar .secondary {
        display: inline-block;
    }
    .actions-toolbar .primary a.action,
    .actions-toolbar .secondary a.action {
        display: inline-block;
    }
    .actions-toolbar .primary .action {
        margin: 0 5px 0 0;
    }
    .actions-toolbar .secondary a.action {
        margin-top: 6px;
    }
    .actions-toolbar > .primary,
    .actions-toolbar > .secondary {
        margin-bottom: 0;
    }
    .actions-toolbar > .primary .action,
    .actions-toolbar > .secondary .action {
        margin-bottom: 0;
        width: auto;
    }
    .modal-popup.modal-slide .modal-footer {
        border-top: 1px solid #c1c1c1;
        text-align: right;
    }
    .brand-images .brands-letter {
        padding-right: 10px;
        width: inherit;
    }
    .brand-images {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .ui-tooltip.ui-widget {
        max-width: 450px;
    }
    .filter-options-content a:hover {
        text-decoration: none;
    }
    .catalog-topnav {
        display: block;
    }
    .page-layout-1column .toolbar-products {
        position: inherit !important;
    }
    .sidebar > .block-category-list {
        margin-bottom: 14px;
    }
    .filter-options input[type='checkbox'] {
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        margin: 0;
        padding: 0;
        width: 0;
        height: 0;
        border: 0;
        opacity: 0;
        position: absolute;
    }
    .block-cart-failed .actions {
        text-align: left;
    }
    .block-cart-failed .actions.primary {
        float: right;
    }
    .bundle-options-container .bundle-options-wrapper,
    .bundle-options-container .product-options-wrapper {
        float: left;
        width: 57%;
    }
    .bundle-options-container .block-bundle-summary {
        float: right;
        position: relative;
        width: 40%;
    }
    .page-layout-2columns-left .bundle-options-container .bundle-options-wrapper,
    .page-layout-2columns-left .bundle-options-container .block-bundle-summary,
    .page-layout-2columns-right .bundle-options-container .bundle-options-wrapper,
    .page-layout-2columns-right .bundle-options-container .block-bundle-summary,
    .page-layout-3columns .bundle-options-container .bundle-options-wrapper,
    .page-layout-3columns .bundle-options-container .block-bundle-summary {
        width: 48%;
    }
    .page-products .products-grid .product-item {
        margin-left: 2%;
        padding: 0;
        width: calc((100% - 4%) / 3);
    }
    .page-products .products-grid .product-item:nth-child(3n + 1) {
        margin-left: 0;
    }
    .page-products.page-layout-1column .products-grid .product-item {
        width: 25%;
    }
    .page-products.page-layout-3columns .products-grid .product-item {
        width: 50%;
    }
    .page-products .columns {
        padding-top: 0;
        position: relative;
        z-index: 1;
    }
    .products.wrapper ~ .toolbar .pages {
        float: left;
    }
    .toolbar-amount {
        float: left;
    }
    .sorter {
        float: right;
    }
    .modes {
        display: inline-block;
        float: left;
        margin-right: 20px;
    }
    .products.wrapper ~ .toolbar .modes {
        display: none;
    }
    .modes-mode {
        color: #7d7d7d;
        border: 1px solid #d1d1d1;
        border-right: 0;
        float: left;
        font-weight: 400;
        line-height: 1;
        padding: 7px 10px;
        text-align: center;
        display: inline-block;
        text-decoration: none;
    }
    .modes-mode:not(.active):hover {
        color: #7d7d7d;
        background: #dedede;
    }
    .modes-mode:last-child {
        border-right: 1px solid #d1d1d1;
    }
    .modes-mode.active {
        color: #a6a6a6;
    }
    .modes-mode > span {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .modes-mode:before {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 24px;
        line-height: inherit;
        color: #7d7d7d;
        content: '\e60d';
        font-family: 'icons-blank-theme';
        vertical-align: middle;
        display: inline-block;
        font-weight: normal;
        overflow: hidden;
        speak: none;
        text-align: center;
    }
    .modes-mode:hover:before {
        color: #7d7d7d;
    }
    .mode-list:before {
        content: '\e60b';
        font-family: 'icons-blank-theme';
    }
    .sidebar .product-items .product-item-info .product-item-photo {
        float: left;
        left: auto;
        margin: 0 10px 10px 0;
        position: relative;
        top: auto;
    }
    .sidebar .product-items .product-item-details {
        margin: 0;
    }
    .sidebar .product-items .product-item-actions {
        clear: left;
    }
    .product-info-main {
        float: right;
    }
    .product.media {
        float: left;
    }
    .page-layout-1column .product-info-main {
        width: 40%;
    }
    .page-layout-1column .product.media {
        width: 57%;
    }
    .page-layout-2columns-left .product-info-main,
    .page-layout-2columns-right .product-info-main,
    .page-layout-3columns .product-info-main {
        width: 48%;
    }
    .page-layout-2columns-left .product.media,
    .page-layout-2columns-right .product.media,
    .page-layout-3columns .product.media {
        width: 50%;
    }
    .block-category-event.block:last-child {
        margin-bottom: 30px;
        padding: 10px 0 30px;
    }
    .block-category-event .block-title {
        margin: 0;
    }
    .block-category-event .block-title strong {
        font-size: 2.4rem;
    }
    .block-category-event .ticker li {
        display: none;
        margin: 0 50px;
    }
    .block-category-event .ticker .value {
        font-size: 6rem;
    }
    .block-category-event .ticker .label {
        font-size: 1.4rem;
        text-transform: none;
    }
    .block-category-event .dates .date {
        font-size: 5rem;
    }
    .block-category-event .dates .start {
        padding-right: 50px;
    }
    .block-category-event .dates .start:after {
        font-size: 5rem;
        right: 10px;
    }
    .checkout-index-index .modal-popup .form-shipping-address {
        max-width: 600px;
    }
    .checkout-index-index .modal-popup .modal-footer .action-save-address {
        float: right;
        margin: 0 0 0 10px;
    }
    .checkout-shipping-method .actions-toolbar > .primary {
        float: right;
    }
    .checkout-shipping-method .actions-toolbar .action.primary {
        margin: 0;
    }
    .table-checkout-shipping-method {
        width: auto;
    }
    .authentication-dropdown {
        background-color: #ffffff;
        border: 1px solid #aeaeae;
        -webkit-transform: scale(1, 0);
        -webkit-transform-origin: 0 0;
        -webkit-transition: -webkit-transform linear 0.1s, visibility 0s linear 0.1s;
        position: absolute;
        text-align: left;
        top: 100%;
        transform: scale(1, 0);
        transform-origin: 0 0;
        transition: transform linear .1s, visibility 0s linear .1s;
        visibility: hidden;
        width: 100%;
    }
    .authentication-dropdown._show {
        z-index: 100;
        -webkit-transform: scale(1, 1);
        -webkit-transition: -webkit-transform linear 0.1s, visibility 0s linear 0s;
        transform: scale(1, 1);
        transition: transform linear .1s, visibility 0s linear 0s;
        visibility: visible;
    }
    .authentication-wrapper {
        width: 33.33333333%;
        text-align: right;
    }

    .checkout-payment-method .actions-toolbar .primary {
        float: right;
        margin: 0;
    }
    .checkout-payment-method .fieldset > .field-select-billing > .control {
        float: none;
        width: 100%;
    }
    .checkout-payment-method .payment-method-content .fieldset > .field {
        margin: 0 0 20px;
    }
    .checkout-payment-method .payment-method-content .fieldset > .field.choice:before {
        padding: 0;
        width: 0;
    }
    .checkout-payment-method .payment-method-content .fieldset > .field.type .control {
        margin-left: 25.8%;
    }
    .checkout-payment-method .payment-method-content .fieldset > .field.type.no-detection .control {
        margin-left: 0;
    }
    .checkout-billing-address .action-update {
        float: right;
    }
    .checkout-billing-address .actions-toolbar .action-cancel {
        margin: 6px 20px 0 0;
    }
    .checkout-payment-method .payment-option-title {
        padding-left: 22px;
    }
    .checkout-payment-method .payment-option-content .payment-option-inner + .actions-toolbar {
        margin-left: 0;
    }
    .contact-index-index .column:not(.sidebar-additional) .form.contact {
        min-width: 600px;
    }
    .login-container .block .login .actions-toolbar > .primary {
        margin-bottom: 0;
        margin-right: 30px;
    }
    .login-container .block .login .actions-toolbar > .secondary {
        float: left;
    }
    .fieldset .fullname .field .label {
        padding: 6px 15px 0 0;
        text-align: right;
        width: 25.8%;
        box-sizing: border-box;
        float: left;
    }
    .fieldset .fullname .field .control {
        width: 74.2%;
        float: left;
    }
    .form.password.reset,
    .form.send.confirmation,
    .form.password.forget,
    .form.create.account,
    .form.search.advanced,
    .form.form-orders-search {
        min-width: 600px;
        width: 50%;
    }
    .account.page-layout-2columns-left .sidebar-main,
    .account.page-layout-2columns-left .sidebar-additional {
        width: 22.3%;
    }
    .account.page-layout-2columns-left .column.main {
        width: 77.7%;
    }
    .block-addresses-list .items.addresses {
        font-size: 0;
    }
    .block-addresses-list .items.addresses > .item {
        display: inline-block;
        font-size: 14px;
        margin-bottom: 20px;
        vertical-align: top;
        width: 48.8%;
    }
    .block-addresses-list .items.addresses > .item:nth-last-child(1),
    .block-addresses-list .items.addresses > .item:nth-last-child(2) {
        margin-bottom: 0;
    }
    .block-addresses-list .items.addresses > .item:nth-child(even) {
        margin-left: 2.4%;
    }
    .control.captcha-image .captcha-img {
        margin: 0 10px 10px 0;
    }
    .page-product-downloadable .product-options-bottom .field.qty + .actions {
        padding-top: 0;
    }
    .gift-message .field {
        margin-bottom: 20px;
    }
    .gift-options {
        position: relative;
        z-index: 1;
    }
    .gift-options .actions-toolbar {
        clear: both;
        float: right;
        position: static;
    }
    .gift-options .actions-toolbar .secondary {
        float: right;
    }
    .gift-options .actions-toolbar .secondary .action {
        float: right;
        margin-left: 20px;
    }
    .gift-options .actions-toolbar .secondary .action-cancel {
        display: block;
        float: left;
        margin-top: 6px;
    }
    .gift-options .actions-toolbar:nth-child(3):before {
        border-left: 1px solid #c1c1c1;
        bottom: 5rem;
        content: '';
        display: block;
        left: 50%;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 0;
    }
    .gift-options-title {
        font-weight: 300;
        font-size: 1.8rem;
    }
    .cart.table-wrapper .action-gift {
        float: left;
    }
    .order-options .gift-wrapping,
    .table-order-review .gift-wrapping {
        max-width: 50%;
    }
    .form-giftregistry-search .fieldset {
        margin-bottom: 29px;
    }
    .form-giftregistry-search .fieldset > .fields-specific-options > .field {
        margin: 0 0 20px;
        box-sizing: border-box;
    }
    .form-giftregistry-search .fieldset > .fields-specific-options > .field > .label {
        margin: 0;
    }
    .form-giftregistry-search .fieldset > .fields-specific-options > .field:before,
    .form-giftregistry-search .fieldset > .fields-specific-options > .field:after {
        content: '';
        display: table;
    }
    .form-giftregistry-search .fieldset > .fields-specific-options > .field:after {
        clear: both;
    }
    .form-giftregistry-search .fieldset > .fields-specific-options > .field.choice:before,
    .form-giftregistry-search .fieldset > .fields-specific-options > .field.no-label:before {
        padding: 6px 15px 0 0;
        width: 25.8%;
        box-sizing: border-box;
        content: ' ';
        float: left;
        height: 1px;
    }
    .form-giftregistry-search .fieldset > .fields-specific-options > .field .description {
        padding: 6px 15px 0 0;
        text-align: right;
        width: 25.8%;
        box-sizing: border-box;
        float: left;
    }
    .form-giftregistry-search .fieldset > .fields-specific-options > .field:not(.choice) > .label {
        padding: 6px 15px 0 0;
        text-align: right;
        width: 25.8%;
        box-sizing: border-box;
        float: left;
    }
    .form-giftregistry-search .fieldset > .fields-specific-options > .field:not(.choice) > .control {
        width: 74.2%;
        float: left;
    }
    .block-giftregistry-shared-items .product-item-photo {
        display: table-cell;
        max-width: 100%;
        padding: 0 20px 0 0;
        vertical-align: top;
        width: 1%;
    }
    .block-giftregistry-shared-items .product-item-details {
        display: table-cell;
        vertical-align: top;
        width: 99%;
        word-break: normal;
    }
    .block-giftregistry-shared-items .col.product {
        width: 48%;
    }
    .block-giftregistry-shared-items .col:not(.product) {
        text-align: center;
    }
    .block-giftregistry-shared-items .col.price {
        padding-top: 17px;
    }
    .block-giftregistry-shared-items .input-text.qty {
        margin-top: -4px;
    }
    .gift-options-cart-item .gift-wrapping,
    .cart-gift-item .gift-wrapping {
        box-sizing: border-box;
        float: left;
        padding-right: 20px;
        width: 50%;
    }
    .gift-options-cart-item .gift-wrapping + .gift-message,
    .cart-gift-item .gift-wrapping + .gift-message {
        border-left: 1px solid #c1c1c1;
        box-sizing: border-box;
        float: left;
        padding-left: 4.5rem;
        width: 50%;
    }
    .form-add-invitations .additional,
    .form-add-invitations .field.text {
        margin-top: 29px;
    }
    .table-invitations .col {
        width: 50%;
    }
    .wishlist.window.popup {
        bottom: auto;
        top: 20%;
        left: 50%;
        margin-left: -212px;
        width: 380px;
        right: auto;
    }
    .wishlist.window.popup .field {
        margin: 0 0 20px;
    }
    .wishlist.window.popup .field:not(.choice) > .label {
        box-sizing: content-box;
        float: none;
        width: auto;
        text-align: left;
        padding: 0;
    }
    .wishlist.window.popup .field:not(.choice) > .control {
        float: none;
        width: auto;
    }
    .wishlist.window.popup .field > .label {
        margin: 0 0 5px;
        display: inline-block;
    }
    .wishlist.window.popup .field.choice:before,
    .wishlist.window.popup .field.no-label:before {
        display: none;
    }
    .block-wishlist-management {
        margin-bottom: 20px;
    }
    .block-wishlist-management .wishlist-select {
        border-bottom: 1px solid #e8e8e8;
        display: table;
        margin-bottom: 15px;
        width: 100%;
    }
    .block-wishlist-management .wishlist-select .wishlist-name {
        display: table-cell;
        margin-right: 10px;
        padding: 5px 10px 10px;
        vertical-align: top;
        white-space: nowrap;
        width: 5%;
    }
    .block-wishlist-management .wishlist-select-items {
        display: table-cell;
        padding-right: 160px;
        vertical-align: top;
    }
    .block-wishlist-management .wishlist-select-items .item {
        display: inline-block;
        margin-right: 10px;
        padding: 5px 10px 10px;
    }
    .block-wishlist-management .wishlist-select-items .item:last-child {
        margin-right: 0;
    }
    .block-wishlist-management .wishlist-select-items .current {
        border-bottom: 3px solid #ff5501;
        font-weight: 600;
    }
    .block-wishlist-management .wishlist-select .wishlist-name-current {
        display: none;
    }
    .block-wishlist-management .wishlist-add.item {
        position: absolute;
        right: 0;
        top: 0;
    }
    .block-wishlist-management .wishlist-title strong {
        font-size: 4rem;
    }
    .block-wishlist-management .wishlist-info {
        float: left;
    }
    .block-wishlist-management .wishlist-toolbar {
        float: right;
    }
    .block-wishlist-info-items .product-item-photo {
        margin-left: 0;
    }
    .products-grid.wishlist .product-item-checkbox {
        float: left;
    }
    .products-grid.wishlist .product-item-checkbox + .product-item-name {
        margin-left: 25px;
    }
    .block.newsletter .field {
        margin-right: 5px;
    }
    .block.newsletter .field .control {
        width: 100%;
    }
    .block.newsletter .action.subscribe {
        border-radius: 3px;
    }
    .products.wrapper.list .product-reviews-summary {
        margin: 0;
    }
    .reward-settings + .actions-toolbar {
        margin-top: -32px;
    }
    .form-create-return .additional .field:last-child {
        margin-top: 29px;
    }
    .magento-rma-guest-returns .column.main .block:not(.widget) .block-content .box {
        margin-bottom: 20px;
    }
    .block-returns-tracking .block-title .action {
        margin: 0 0 0 30px;
    }
    .block-returns-tracking .block-title .actions-track {
        float: right;
        margin-top: 12px;
    }
    .table-order-items .subtotal,
    .table-order-items .amount {
        text-align: right;
    }
    .table-order-items.creditmemo .col.qty,
    .table-order-items.creditmemo .col.discount,
    .table-order-items.creditmemo .col.subtotal {
        text-align: center;
    }
    .table-order-items.creditmemo .col.total {
        text-align: right;
    }
    .order-pager-wrapper .order-pager-wrapper-top {
        padding-left: 0;
        padding-right: 0;
    }
    .order-pager-wrapper .pages {
        float: right;
    }
    .order-actions-toolbar .action.print {
        display: block;
        float: right;
    }
    .page-title-wrapper .order-date {
        margin-top: -20px;
    }
    [class^='sales-guest-'] .column.main .block:not(.widget) .block-content .box,
    .sales-guest-view .column.main .block:not(.widget) .block-content .box {
        margin-bottom: 20px;
    }
    .page-wrapper > .breadcrumbs,
    .page-wrapper > .top-container,
    .page-wrapper > .widget {
        box-sizing: border-box;
        width: 100%;
    }
    .header.panel > .header.links {
        float: right;
        margin: 0;
        padding: 0;
        list-style: none none;
        margin-left: auto;
    }
    .header.panel > .header.links > li {
        display: inline-block;
        vertical-align: top;
    }
    .header.panel > .header.links > li {
        margin: 0 0 0 15px;
    }
    .header.panel > .header.links > li.welcome,
    .header.panel > .header.links > li a {
        line-height: 1.4;
    }
    .header.panel > .header.links > li.welcome a {
        padding-left: 5px;
    }
    .page-header {
        border: 0;
        margin-bottom: 0;
    }
    .page-header .panel.wrapper {
        border-bottom: 1px solid #e8e8e8;
    }
    .page-header .header.panel {
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .page-header .switcher {
        display: inline-block;
    }
    .page-main > .page-title-wrapper .page-title {
        display: inline-block;
    }
    .page-main > .page-title-wrapper .page-title + .action {
        float: right;
        margin-top: 20px;
    }
    .logo img {
        max-height: inherit;
    }
    .footer.content .links {
        display: inline-block;
        margin-bottom: 20px;
        padding: 0 50px 0 0;
        vertical-align: top;
    }
    .footer.content .switcher.store {
        display: inline-block;
        padding-right: 50px;
        vertical-align: top;
    }
    .my-credit-cards .card-type img {
        display: block;
    }
    .products-grid.wishlist .product-item-tooltip {
        display: inline-block;
    }
    .products-grid.wishlist .product-item-actions {
        margin: 10px 0 0;
    }
    .products-grid.wishlist .product-item .fieldset .field.qty {
        margin-bottom: 10px;
        padding-right: 10px;
    }
    .products-grid.wishlist .product-item .fieldset .field.qty .label {
        width: auto;
    }
    .products-grid.wishlist .product-item .box-tocart .actions-primary {
        margin: 0;
    }
    .products-grid.wishlist .product-item .box-tocart .stock {
        margin: 20px 0 0;
    }
    .wishlist-index-index .product-item-info {
        width: 240px;
    }
    .block.widget .products-grid .product-item {
        width: 33.33333333%;
    }
    .sidebar .block.widget .products-grid .product-item {
        margin-left: 0;
        width: 100%;
    }
    .sidebar .block.widget .products-grid .product-item .actions-secondary {
        display: block;
        padding: 10px 0;
    }
    .page-layout-1column .block.widget .products-grid .product-item {
        margin-left: 2%;
        width: calc((100% - 6%) / 4);
    }
    .page-layout-1column .block.widget .products-grid .product-item:nth-child(3n + 1) {
        margin-left: 2%;
    }
    .page-layout-1column .block.widget .products-grid .product-item:nth-child(4n + 1) {
        margin-left: 0;
    }
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 50%;
    }
    .sidebar .block.widget .pager .pages-item-next {
        padding: 0;
    }
    .sidebar .block.widget .pager .pages-item-next .action {
        margin: 0;
    }
}
@media all and (min-width: 1024px), print {
    .brand-images .brands-letter {
        padding-right: 35px;
        width: inherit;
    }
    .brand-images .brands-letter .brand-item {
        margin: 0 10px 20px;
    }
    .products-grid .product-item {
        width: 20%;
    }
    .page-layout-1column .products-grid .product-item {
        width: 16.66666667%;
    }
    .page-layout-3columns .products-grid .product-item {
        width: 25%;
    }
    .page-products .products-grid .product-items {
        margin: 0;
    }
    .page-products .products-grid .product-item {
        margin-left: 2%;
        padding: 0;
        width: calc((100% - 6%) / 4);
    }
    .page-products .products-grid .product-item:nth-child(3n + 1) {
        margin-left: 2%;
    }
    .page-products .products-grid .product-item:nth-child(4n + 1) {
        margin-left: 0;
    }
    .page-products.page-layout-1column .products-grid .product-item {
        margin-left: 0;
        width: 20%;
    }
    .page-products.page-layout-3columns .products-grid .product-item {
        margin-left: 1%;
        width: 32.667%;
    }
    .page-products.page-layout-3columns .products-grid .product-item:nth-child(3n) {
        margin-left: 1%;
    }
    .page-products.page-layout-3columns .products-grid .product-item:nth-child(3n + 1) {
        margin-left: 0;
    }
    .checkout-index-index .modal-popup .modal-inner-wrap {
        margin-left: -400px;
        width: 800px;
        left: 50%;
    }
    .table-checkout-shipping-method {
        min-width: 500px;
    }
    .block.widget .products-grid .product-item {
        width: 20%;
    }
    .page-layout-1column .block.widget .products-grid .product-item {
        margin-left: 2%;
        width: calc((100% - 8%) / 5);
    }
    .page-layout-1column .block.widget .products-grid .product-item:nth-child(4n + 1) {
        margin-left: 2%;
    }
    .page-layout-1column .block.widget .products-grid .product-item:nth-child(5n + 1) {
        margin-left: 0;
    }
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 25%;
    }
    .block.widget .products-grid .product-items {
        margin: 0;
    }
    .block.widget .products-grid .product-item {
        margin-left: calc((100% - 4 * 24.439%) / 3);
        padding: 0;
        width: 24.439%;
    }
    .block.widget .products-grid .product-item:nth-child(4n + 1) {
        margin-left: 0;
    }
}
@media all and (min-width: 1440px), print {
    .brands-filters .letter {
        margin: 0;
        border-radius: 0;
        border-right: none;
    }
    .brands-filters .letter-all {
        margin-right: 20px;
    }
    .brands-filters .letter:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .brands-filters .letter:last-child {
        border-right: 1px solid #ccc;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .sidebar .product-items .product-item-info .product-item-photo {
        float: none;
        left: 0;
        margin: 0;
        position: absolute;
        top: 0;
    }
    .sidebar .product-items .product-item-details {
        margin-left: 85px;
    }
}
