body.aw-et-product-page,
body.contentmanager-contenttype-actu,
body.contentmanager-contenttype-projet,
body.contentmanager-contenttype-edition {
    font-size: 14px;

    .page-main {
        max-width: $container-sm;
    }
    .edition-main {
        flex-direction: row;
        justify-content: space-between;
    }
    .edition-cover {
        width: 300px;
        height: 375px;
    }
    .edition-data-panel {
        min-width: unset;
        width: 55%;
        &-header {
            .edition-title {
                font-size: 1.5rem;
            }
        }
        .summary {
            .summary-title {
                margin-bottom: 2px;
                font-size: 1.1rem;
            }
            .summary-listing {
                height: 230px;
                overflow-y: auto;
            }
            .subscribe {
                margin-top: 16px
            }
        }
    }
    .edition-related{
        &-list {
            &-item {
                &::before {
                    content: unset;
                }

                &-header {
                    margin-bottom: 0;

                    &-name {
                        padding-bottom: 0;
                        padding-top: 0;
                        padding-left: 1.25rem; //1.25rem is $related-contents__items-gap for sm
                        left: -1.25rem;
                    }

                    &-line {
                        display: inline-block;
                    }
                }

                &-body {
                    @include box-shadow;
                    @include box-shadow-hover;
                    flex-direction: column;
                    height: calc(100% - 1rem);
                    width: auto;

                    &-img {
                        aspect-ratio: 1;

                        &-container {
                            height: 191.25px;
                            width: auto;
                        }
                    }

                    &-content {
                        width: 100%;
                        height: 100%;
                        padding-top: 0;
                    }
                }

                &.last-related {
                    display: block;
                }

                &:nth-child(4n+1) {
                    .edition-related-list-item-header-line {
                        width: 100%;
                    }
                }
            }
        }
    }

    .actu-article,
    .edition-article {
        position: relative;
        &-header {
            position: relative;
            width: 100vw;
            margin-bottom: 20px;
            margin-left: calc((-100vw + #{$container-sm}) / 2);
            padding-left: calc((100vw - #{$container-sm}) / 2);
        }
        &-body {
            display: block;
            margin-top: 4rem;
            &-navigator {
                width: 280px;
                background-color: $lightest-grey;
                float: left;
                display: block;
                position: sticky;
                top: $top-sticky-offset;
                &-body {
                    display: block !important;
                }
                &-title {
                    display: flex;
                    justify-content: space-between;
                }
            }
            &-main {
                width: calc(100% - 300px);
                margin-left: 300px;
            }
        }
    }
}

.edition-progress-bar {
    top: 105px;
}

.page-wrapper:has(.welcome-wrapper) .edition-progress-bar {
    top: calc(105px + #{$welcome-banner-height});
}

body.edition-page {
    .edition-related {
        &-list {
            .subscription-sticker {
                top: calc((33vw - 20px) / 4 * 3);
            }
        }
    }
}

$pouet: 40vw;

.edition-publication-container {
    .edition-publication {
        flex-direction: row;
        min-height: calc(#{$pouet} / 210 * 297);
        .edition-panel-picture {
            margin-inline: calc(#{$container-sm} / 20);
            max-width: $pouet;
            height: calc(#{$pouet} / 210 * 297);
            overflow: visible;
            position: relative;
            img {
                width: 100%;
                border: 1px solid $lighter-grey;
                aspect-ratio: 210/297;
                position: relative;
                height: auto;
                object-fit: unset;
            }
        }
    }
}
