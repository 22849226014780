.nav-sections {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    -webkit-flex-basis: auto;
    flex-basis: auto;
}

.nav-sections-item-content {
    > * {
        display: none;
    }

    > .navigation {
        display: block;
    }
}

.nav-toggle {
    display: none;
}

.navigation {
    top: 0;
    left: auto;
    z-index: 3;
    width: 100%;
    height: inherit;
    padding: 0;
    overflow: inherit;

    &:empty {
        display: none;
    }
    li.level0 {
        > a, > span {
            border-style: solid;
            border-width: 0 0 5px;
            border-color: transparent;

            &:hover {
                border-color: $primary;
            }
        }

        &.level0-active-desktop {
            > .level0.submenu {
                display: block !important;
            }
        }
    }
    .level0 {
        &.parent {
            &:hover {
                > .submenu {
                    overflow: visible !important;
                }
            }
            > .level-top {
                padding-right: 20px;
                > .ui-menu-icon {
                    position: absolute;
                    right: 0;
                    display: inline-block;
                    text-decoration: none;
                    > span {
                        border: 0;
                        clip: rect(0, 0, 0, 0);
                        height: 1px;
                        margin: -1px;
                        overflow: hidden;
                        padding: 0;
                        position: absolute;
                        width: 1px;
                    }
                    &::after {
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        font-size: 12px;
                        line-height: 20px;
                        color: inherit;
                        content: '\e622';
                        font-family: 'icons-blank-theme';
                        vertical-align: middle;
                        display: inline-block;
                        font-weight: normal;
                        overflow: hidden;
                        speak: none;
                        text-align: center;
                    }
                }
            }
        }
        .submenu {
            min-width: 230px;
            padding: 0;
            display: none;
            left: 0;
            margin: 0 !important;
            z-index: 1;
            margin-top: 11px;

            > ul {
                margin-top: 11px;
                &::before {
                    color: $white;
                    left: 20px;
                    top: -20px;
                    border: 10px solid transparent;
                    height: 0;
                    width: 0;
                    border-bottom-color: $white;
                    z-index: 4;
                }
                &::after {
                    border: 11px solid transparent;
                    height: 0;
                    width: 0;
                    border-bottom-color: $lighter-grey;
                    color: $lighter-grey;
                    left: 19px;
                    top: -22px;
                    z-index: 3;
                }
            }
            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 4px;
                left: 0;
                top: -4px;
                z-index: 1;
            }
            a {
                display: inline-block;
                line-height: inherit;
                color: $black;
            }
            .active {
                & > a {
                    border-color: $primary;
                    border-style: solid;
                    border-width: 0 0 0 3px;
                }
            }
            .submenu-reverse {
                left: auto !important;
                right: 100%;
            }
            li {
                margin: 0;
                position: relative;
                &.parent {
                    > a {
                        > .ui-menu-icon {
                            position: absolute;
                            right: 3px;
                            display: inline-block;
                            text-decoration: none;
                            > span {
                                border: 0;
                                clip: rect(0, 0, 0, 0);
                                height: 1px;
                                margin: -1px;
                                overflow: hidden;
                                padding: 0;
                                position: absolute;
                                width: 1px;
                            }
                            &::after {
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                font-size: 12px;
                                line-height: 20px;
                                color: inherit;
                                content: '\e608';
                                font-family: 'icons-blank-theme';
                                vertical-align: middle;
                                display: inline-block;
                                font-weight: normal;
                                overflow: hidden;
                                speak: none;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
        &.more {
            position: relative;
            display: inline-block;
            text-decoration: none;
            &::after {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 26px;
                line-height: inherit;
                color: inherit;
                content: '\e607';
                font-family: 'icons-blank-theme';
                vertical-align: middle;
                display: inline-block;
                font-weight: normal;
                overflow: hidden;
                speak: none;
                text-align: center;
                cursor: pointer;
                padding: 8px 12px;
                position: relative;
                z-index: 1;
            }
            &::before {
                display: none;
            }
            &:hover {
                > .submenu {
                    overflow: visible !important;
                }
            }
            li {
                display: block;
            }
        }
    }
    ul {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        font-size: 0;
        > * {
            font-size: 1rem;
        }
    }
    li {
        &.level0 {
            border-top: none;

            > span:after {
                content: '';
            }
        }
        .level0 {
            margin: 0;
            display: inline-block;
            &:last-child {
                margin-right: 0;
                padding-right: 0;
            }
        }
        &.level1 {
            position: relative;
        }
        &:hover {
            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 100%;
                width: 10px;
                height: calc(100% + 3px);
                z-index: 1;
            }
        }
        > .level-top {
            color: #575757;
            line-height: 53px;
            padding: 0 12px;
            text-decoration: none;
            box-sizing: border-box;
            position: relative;
            display: inline-block;
        }
    }

    &:not(:hover) {
        li.level0 {
            &.active, &.has-active {
                > a, > span {
                    border-color: $primary;
                    text-shadow: 0 0 1px $black; //Like a font-weight: 700 but without creating an offset with weight change on hover
                }
            }
        }
    }
}

.navigation .level0 .submenu > ul:before,
.navigation .level0 .submenu > ul:after {
    content: '';
    display: block;
    overflow: hidden;
    position: absolute;
}

.navigation .level0 .submenu a:hover,
.navigation .level0 .submenu a.ui-state-focus {
    color: $black;
    text-decoration: none;
}

/*****************************/
/***** MOBILE NAV FOOTER *****/
/*****************************/

.mobile-nav-footer {
    display: none;
}
