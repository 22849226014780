.cms-no-route {
    &#html-body {
        .pagebuilder-column-group {
            flex-direction: row;
        }

        .cms-no-route__splash-img,
        .cms-no-route__desc {
            width: 50%;
        }
    }

    .page-main {
        max-width: 100%;
    }

    .columns {
        .column.main {
            padding-bottom: 0;
        }
    }

    .cms-no-route__splash-img {
        height: 75vh;
    }

    .cms-no-route__splash-txt {
        align-self: end;
        margin: 0 10%;
    }

    .footer.content {
        margin-top: 0;
    }
}
