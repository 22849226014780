/* SIPL = Side Image Product Layout */

$sipl-info-main__width: 380px;
$sipl-content__mid-margin: 7.5rem;

.sipl {
    .sipl__content-wrapper {
        max-width: $container-hd;
    }

    .sipl__heading-container {
        .sipl__content-wrapper {
            padding-right: calc(#{$sipl-content__mid-margin} + #{$sipl-info-main__width});
        }
    }

    .sipl__content-column {
        padding-right: $sipl-content__mid-margin;
        width: calc(100% - #{$sipl-info-main__width})
    }

    .sipl__side-container {
        float: right;
        position: sticky;
        top: $top-sticky-offset;
        margin-top: -15.5rem;
        margin-bottom: 0;
        width: $sipl-info-main__width;

        .product.media {
            width: 100%;
        }

        .product-info-main {
            float: none;
            width: 100%;
        }
    }
}
