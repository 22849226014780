.minicart-wrapper {
    margin-left: 13px;

    .block-minicart {
        right: -15px;
        width: 390px;
    }

    .action.showcart {
        .counter {
            &.qty {
                top: 0;
            }
        }
    }
}

.minicart-items-wrapper {
    max-height: 70vh;
}
