.card-list {
    flex-direction: row;

    .card-list__item {
        margin-bottom: 4rem;
    }
}

.card-item {
    min-height: 24rem;

    .card-item__sticker {
        font-size: 0.75rem;
    }
}
