.contentmanager-contentlist-view {
    .title-block {
        padding-left: 0;
    }
}

.glcl-list {
    display: flex;

    .glcl-list__col {
        width: 50%;

        &:first-child {
            margin-right: 7.625rem;
        }
    }

    .glcl-group-box {
        margin-bottom: 6.25rem;
    }
}

.glcl-group-box {
    padding: 2.5rem;

    .glcl-group-box__header {
        cursor: unset;

        &::after {
            display: none;
        }
    }

    .glcl-group-box__app-list {
        max-height: none;
    }

    &.glcl-group-box--active {
        .glcl-group-box__app-list {
            max-height: none;
        }
    }
}

.glcl-accordion-item {
    .glcl-accordion-item__desc {
        margin-bottom: 1.5rem;
    }
}
