$event-infos__m-bottom: 2.5rem;

.aw-et-product-page {
    .title-block {
        h3 {
            display: inline-block;
        }
    }

    .attendee-element {
        .fieldset {
            .ctiflAccordion__inside {
                font-size: 0;

                > .field {
                    display: inline-block;
                    margin-right: 8%;
                    max-width: 25.33%;
                    font-size: 1rem;

                    &:nth-child(3n) {
                        margin-right: 0;
                        max-width: 33.33%;
                    }

                    &.aw-et-hour-grid-field {
                        max-width: 100%;
                    }
                }
            }
        }

        .field-error {
            float: left;
        }
    }

    .aw-et-accordion {
        margin-bottom: $event-infos__m-bottom;
    }
}
